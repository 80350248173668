import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  meta:{isLogin:true},
  component: () => import('../views/index.vue')
},{
  path: '/device',
  name: 'device',
  meta:{isLogin:true},
  component: () => import('../device/index.vue')
},{
  path: '/call',
  name: 'call',
  meta:{isLogin:true},
  component: () => import('../call/index.vue')
},{
  path: '/login',
  name: 'login',
  component: () => import('../user/login.vue')
}]
const router = new VueRouter({
  // mode: "history",
  mode: "hash",
  routes
})

export default router