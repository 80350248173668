/**
 * @description 封装axios通用接口 pengjunfu
 */
import qs from "qs";
import axios from "axios";
import config from "../config/";
// import notification from "ant-design-vue/es/notification";
// let config = window.config;
let apiUrl = config.apiUrl;
let verifyParams = {};
// eslint-disable-next-line
if (process.env.VUE_APP_MOCK === "true") {
  // apiUrl = config.apiDebugUrl;
  // console.log(config);
  verifyParams = {
    CacheUserName: process.env.VUE_APP_CACHEUSERNAME,
    CachePassword: process.env.VUE_APP_CACHEPASSWORD,
    CacheNoRedirect: process.env.VUE_APP_CACHENOREDIRECT
  };
}
// 拦截器,在请求url前加上baseUrl地址
axios.interceptors.request.use(
  axiosConfig => {
    Object.keys(config.proxyTable).forEach(key => {
      if (axiosConfig.url.indexOf(key) > -1 && key.indexOf(".csp") > -1) {
        axiosConfig.url = `${config.proxyTable[key]}${axiosConfig.url}`;
      } else if (
        axiosConfig.url.indexOf(".csp") < 0 &&
        key.indexOf(".csp") < 0
      ) {
        axiosConfig.url = `${config.proxyTable[key]}${axiosConfig.url}`;
      }
    });
    return axiosConfig;
  },
  error => Promise.reject(error)
);
/// response拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code) {
      if (response.data.code === 0) {
        return response;
      } else {
        console.log(response.data.error);
        // notification.error({
        //   message: "捕获异常",
        //   description: response.data.error,
        //   duration: 0
        // });
      }
    }
    return response;
  },
  error => Promise.reject(error)
);

export default {
  ajax(api, ...args) {
    // console.log(api);
    if (api.mock) {
      return new Promise(function(resolve) {
        resolve(api.mockFT());
      });
    }
    const { type, url } = api;
    const params = Object.assign(
      {
        url
      },
      verifyParams
    );
    const config = {
      method: type,
      url: apiUrl
    };
    for (let i = 0; i < args.length; i += 1) {
      params[`p${i + 1}`] =
        typeof args[i] === "object"
          ? JSON.stringify(args[i])
          : args[i] !== undefined
          ? args[i]
          : "";
    }
    if (type == "get") {
      config.params = params;
    } else {
      config.data = qs.stringify(params);
    }
    return axios(config).then(response => response.data);
  }
};
