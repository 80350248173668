<template>
  <div id="app">
    <router-view  v-bind="loginInfo"/>
  </div>
</template>
<script>
		// console.log(axios);
import { getLoginInfo, getLocalLoginInfo } from "./api/login";
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = decodeURI(window.location.search)
    .substr(1)
    .match(reg);
  if (r != null) {
    return decodeURI(unescape(r[2]));
  }
  return null;
}
var loginInfo={}

// eslint-disable-next-line
	// console.log(process.env.VUE_APP_MOCK);
if (process.env.VUE_APP_MOCK === "true") {
  require("./config/mock.js");
  loginInfo = getLocalLoginInfo();
  let pageName = getUrlParam("pageName");
  if (pageName) {
    loginInfo.episodeID = getUrlParam("episodeID");
    loginInfo.leftMenuCode = getUrlParam("leftMenuCode");
    // init(mergeLoginInfo(loginInfo), pageName);
  } else {
    // init(mergeLoginInfo(loginInfo), developPageName);
  }
} else {
  /*
  getLoginInfo().then((logInfo) => {
    // const pageName = window.pageName;
    loginInfo.episodeID = window.episodeID;
    loginInfo.componentName = window.componentName;
    loginInfo.leftMenuCode = window.leftMenuCode;
    loginInfo.ifBaby = window.ifBaby;
    loginInfo.episodeIDs = window.episodeIDs;
    Object.assign(loginInfo,logInfo);
    // init(loginInfo, pageName);
  });
  */
}
export default {
	data() {
		return {
			loginInfo

		}
	},
	created() {
		// console.log(loginInfo);
	}


// request
// getUri
// delete
// get
// head
// options
// post
// put
// patch
// defaults
// interceptors

}
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  background-color: #020308;
  overflow: hidden;
}
</style>
